import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import "./index.css";

export default function ScheduleInfo(props){
    const {id, name,  startTime, endTime, status , color} = props;

    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleDetails = () => {
        setIsCollapsed(!isCollapsed);
    }
    return (
        <>
            <tr className="clickable" onClick={() => {
                toggleDetails();
            }}>
                <td className="d-flex flex-row">
                    <button className="btn btn-default btn-sm pt-0 pb-0">
                        <i className="bx bxs-plus-circle h5 mb-0 text-primary"></i>
                    </button>
                    <span className="pb-1">
                        {id}
                    </span>
                </td>
                <td>
                    <i>{status === "ACTIVE" ? "Hoạt động" : "Không hoạt động"}</i>
                </td>
            </tr>
            {!isCollapsed && <>
            <tr className="extended-row-item">
                <td>Giờ bắt đầu</td>
                <td>
                    <i>{startTime}</i>
                </td>
            </tr>
            <tr className="extended-row-item">
                <td>Giờ kết thúc</td>
                <td>
                    <i>{endTime}</i>
                </td>
            </tr>
            <tr className="extended-row-item">
                <td>Tên ca làm việc</td>
                <td>
                    <span>{name}</span>
                </td>
            </tr>
            <tr className="extended-row-item">
                <td>Màu</td>
                <td >
                    <div className="box-color" style={{backgroundColor: `${color}`}}></div>
                </td>
            </tr>
            <tr className="extended-row-item">
                <td>Tác vụ</td>
                <td>
                    <Link to={`/schedule/edit/${id}`}>
                        Chỉnh sửa
                    </Link>
                </td>
            </tr>
            </>}
        </>
    )
}