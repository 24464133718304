import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Branch
import Branch from "../pages/Branch/list";
import BranchDetails from "../pages/Branch/list/details";
import NewBranch from "../pages/Branch/new";
import EmployeeList from "../pages/Employee/list";
import EmployeeDetails from "../pages/Employee/list/employee-details";
import EmPloyeeNew from "../pages/Employee/new";
import ScheduleNewWork from "../pages/Schedule/new/new-work";
import DayOffList from "../pages/Manage-dayoff/list";
import DayOffNew from "../pages/Manage-dayoff/new";
import ScheduleWorkManage from "../pages/schedule-manage/work/new";
import ScheduleDetailWork from "../pages/schedule-manage/work/details";
import ScheduleList from "../pages/Schedule/list";
import ScheduleDetails from "../pages/Schedule/list/schedule-details";
import ScheduleNewClean from "../pages/schedule-manage/cleanup/new";
import ScheduleDetailCleanup from "../pages/schedule-manage/cleanup/details";
import DayOffCalendar from "../pages/Manage-dayoff/calendar";
import NewTimeKeeping from "../pages/Timekeeping/new";
import ListTimekeeping from "../pages/Timekeeping/list";
import EditTimekeeping from "../pages/Timekeeping/edit";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  // Branch
  { path: "/branch", component: Branch },
  { path: "/branch/details/:code", component: BranchDetails },
  { path: "/branch/edit/:code", component: BranchDetails },
  { path: "/branch/new", component: NewBranch },
  // Employee
  { path: "/employee/list", component: EmployeeList },
  { path: "/employee/details", component: EmployeeDetails},
  { path: "/employee/edit", component: EmployeeDetails},
  { path: "/employee/new", component: EmPloyeeNew},
  // Schedule
  { path: "/schedule/new/work", component: ScheduleNewWork},
  { path: "/schedule/list/work", component: ScheduleList},
  { path: "/schedule/edit/:code", component: ScheduleDetails },
  // Manage Schedule
  { path: "/schedule/manage/work", component: ScheduleWorkManage},
  { path: "/schedule/details/work", component: ScheduleDetailWork},
  { path: "/schedule/manage/cleanup", component: ScheduleNewClean},
  { path: "/schedule/details/cleanup", component: ScheduleDetailCleanup},
  // Manage Day-off
  { path: "/dayoff/list", component: DayOffList },
  { path: "/dayoff/calendar", component: DayOffCalendar },
  { path: "/dayoff/new", component: DayOffNew },
    // Timekeeping
  { path : "/new/timekeeping" , component: NewTimeKeeping},
  { path : "/list/timekeeping" , component: ListTimekeeping},
  { path: "/edit/timekeeping", component: EditTimekeeping},
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

    // Authentication Inner
    { path: "/pages-login", component: Login1 },
    { path: "/pages-register", component: Register1 },
    { path: "/page-recoverpw", component: Recoverpw },
    { path: "/auth-lock-screen", component: LockScreen },
]

export { userRoutes, authRoutes }