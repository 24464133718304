import React, {useState} from "react";
import {Button, Col, Input, Label, Row} from "reactstrap";
import toastr from "toastr";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToastErrorDefault, showToastSuccessDefault } from "../../../store/actions";
import { POST_BRANCH_CREATE } from "../../../helpers/url_helper";
import { post } from "../../../helpers/api_helper";

export default function NewBranch(){
    const dispatch = useDispatch();
    const history = useHistory();
    const [branchDetail, setBranchDetail] = useState({
        name: "",
        address: "",
        userManager: "",
        status: "ACTIVE"
    });
    const [managerDetail, setManagerDetail] = useState({
        fullNameManager: "",
        phoneManager: ""
    });
    const [invalidForm, setInvalidForm] = useState({
        name: false,
        address: false,
        fullNameManager: false,
        phoneManager: false
    });

    const handleSubmit = () => {
        const validateForm = {
            name: branchDetail.name.trim() === "",
            address: branchDetail.address.trim() === "",
            fullNameManager: branchDetail.userManager.trim() !== "" && managerDetail.fullNameManager.trim() === "",
            phoneManager: branchDetail.userManager.trim() !== "" && managerDetail.phoneManager.trim() === ""
        }
        setInvalidForm(validateForm);

        if (Object.values(validateForm).every(value => value === false)) {
            post(POST_BRANCH_CREATE, branchDetail).then(response => {
                if (response.status === 200) {
                    dispatch(showToastSuccessDefault("Thêm mới chi nhánh thành công!"));
                    history.replace("/branch");
                }
            }).catch(error => {
                if (error.response.data.status === 500) {
                    dispatch(showToastErrorDefault("Lỗi hệ thống"));
                } else {
                    dispatch(showToastErrorDefault(error.response.data.message));
                }
            });
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CHI NHÁNH</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Chi nhánh </b>
                        <p className="mb-0"> / Thêm mới</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h4 className="heading">Thêm mới</h4>
                    <div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="branchName" className="label asterisk">Tên chi nhánh</Label>
                            <Input
                                invalid={invalidForm.name}
                                id="branchName"
                                name="branchName"
                                className="input-edit-info"
                                type="text"
                                placeholder="Nhập tên chi nhánh"
                                value={branchDetail.name}
                                onChange={(e) => {
                                    setBranchDetail({ ...branchDetail, name: e.target.value });
                                }}
                                required
                            />
                            {invalidForm.name && <p className="message-err">
                                Chưa nhập tên chi nhánh
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="address" className="label asterisk">Địa chỉ</Label>
                            <Input
                                invalid={invalidForm.address}
                                id="address"
                                name="address"
                                className="input-edit-info input-textarea"
                                type="textarea"
                                placeholder="Nhập địa chỉ chi nhánh"
                                value={branchDetail.address}
                                onChange={(e) => {
                                    setBranchDetail({ ...branchDetail, address: e.target.value });
                                }}
                                required
                            />
                            {invalidForm.address && <p className="message-err">
                                Chưa nhập địa chỉ chi nhánh
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="username" className="label">Tên đăng nhập tài khoản quản lý</Label>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <Input
                                    id="username"
                                    className="input-edit-info"
                                    name="username"
                                    type="text"
                                    placeholder="Nhập tên đăng nhập tài khoản"
                                    value={branchDetail.userManager}
                                    onChange={(e) => {
                                        setBranchDetail({ ...branchDetail, userManager: e.target.value });
                                    }}
                                />
                                <Button
                                    className="btn-test"
                                    color="info"
                                    outline
                                >
                                    <span>
                                        Kiểm tra
                                    </span>
                                </Button>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="manager" className="label">Họ tên quản lý</Label>
                            <Input
                                invalid={invalidForm.fullNameManager}
                                id="manager"
                                className="input-edit-info disabled"
                                name="manager"
                                type="text"
                                value={managerDetail.fullNameManager}
                                placeholder="Tự động nhập từ tên đăng nhập"
                                disabled
                            />
                            {invalidForm.fullNameManager && <p className="message-err">
                                Chưa kiểm tra tên đăng nhập
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="phone" className="label">Số điện thoại quản lý</Label>
                            <Input
                                invalid={invalidForm.phoneManager}
                                id="phone"
                                className="input-edit-info disabled"
                                name="phone"
                                type="text"
                                value={managerDetail.phoneManager}
                                placeholder="Tự động nhập từ tên đăng nhập"
                                disabled
                            />
                            {invalidForm.phoneManager && <p className="message-err">
                                Chưa kiểm tra tên đăng nhập
                            </p>}
                        </div>
                        <div>
                            <Label htmlFor="status" className="label asterisk">Trạng thái</Label>
                            <div className="d-flex flex-row align-items-center">
                                <div className="form-switch me-1">
                                    <input
                                        className="form-check-input"
                                        name="status"
                                        type="checkbox"
                                        role="switch"
                                        id="switchCheckChecked"
                                        checked={branchDetail.status === "ACTIVE"}
                                        onChange={() => setBranchDetail({ ...branchDetail, status: branchDetail.status === "ACTIVE" ? "INACTIVE" : "ACTIVE" })}
                                        required
                                    />
                                </div>
                                <span>
                                    {branchDetail.status === "ACTIVE" ? '(Hoạt động)' : '(Không hoạt động)'}
                                </span>

                            </div>
                        </div>
                    </div>
                    <div className="mt-3 d-flex flex-row justify-content-end">
                        <Button
                            color="success"
                            outline
                            className="me-2"
                            onClick={handleSubmit}
                        >
                            Thêm mới
                        </Button>
                        <Button
                            color="danger"
                            outline
                        >
                            <Link to="/branch">
                                Huỷ bỏ
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}