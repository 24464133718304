import { Button, Col, FormText, Input, Label, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import toastr from "toastr";
import "./index.css"
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { post } from "../../../../helpers/api_helper";
import { POST_BRANCH_DETAIL, POST_BRANCH_UPDATE } from "../../../../helpers/url_helper";
import { useDispatch } from "react-redux";
import { showToastErrorDefault, showToastSuccessDefault } from "../../../../store/actions";
export default function BranchDetails() {
    const { code } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const [branchDetail, setBranchDetail] = useState({
        code: code,
        name: "",
        address: "",
        userManager: "",
        status: ""
    });
    const [managerDetail, setManagerDetail] = useState({
        fullNameManager: "",
        phoneManager: ""
    });
    const [invalidForm, setInvalidForm] = useState({
        name: false,
        address: false,
        fullNameManager: false,
        phoneManager: false
    });

    const { pathname } = useLocation();
    const isEdit = !pathname.includes('/branch/details');

    useEffect(() => {
        post(POST_BRANCH_DETAIL, { code }).then(response => {
            if (response.status === 200) {
                console.log(response.body)
                const data = response.body;
                setBranchDetail({
                    ...branchDetail,
                    name: data.name ?? "",
                    address: data.address ?? "",
                    manager: data.manager ?? "",
                    status: data.status
                });

                setManagerDetail({
                    fullNameManager: data.fullNameManager ?? "",
                    phoneManager: data.phoneManager ?? ""
                });
            }
        })
    }, []);

    const handleSubmit = () => {
        if (isEdit) {
            const validateForm = {
                name: branchDetail.name.trim() === "",
                address: branchDetail.address.trim() === "",
                fullNameManager: branchDetail.userManager.trim() !== "" && managerDetail.fullNameManager.trim() === "",
                phoneManager: branchDetail.userManager.trim() !== "" && managerDetail.phoneManager.trim() === ""
            }
            setInvalidForm(validateForm);

            if (Object.values(validateForm).every(value => value === false)) {
                post(POST_BRANCH_UPDATE, branchDetail).then(response => {
                    if (response.status === 200) {
                        dispatch(showToastSuccessDefault("Sửa chi nhánh thành công!"));
                        history.replace(`/branch/details/${code}`);
                    }
                }).catch(error => {
                    if (error.response.data.status === 500) {
                        dispatch(showToastErrorDefault("Lỗi hệ thống"));
                    } else {
                        dispatch(showToastErrorDefault(error.response.data.message));
                    }
                });
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CHI NHÁNH</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Chi nhánh </b>
                        <p className="mb-0"> / {isEdit ? 'Chỉnh sửa' : 'Chi tiết'}</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h4 className="heading">{isEdit ? 'Chỉnh sửa' : 'Chi tiết'}</h4>
                    <div>
                        <div className="d-flex flex-column ">
                            <Label htmlFor="id" className="label asterisk">Mã chi nhánh</Label>
                            {
                                isEdit ? <Input id="id" name="id" className="input-edit-info disabled" type="text" value={branchDetail.code} disabled required /> : <p className="info">{branchDetail.code}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="branchName" className="label asterisk">Tên chi nhánh</Label>
                            {
                                isEdit ?
                                    <>
                                        <Input id="branchName" name="branchName" className="input-edit-info" type="text" onChange={(e) => setBranchDetail({ ...branchDetail, name: e.target.value })} value={branchDetail.name} required />
                                        {invalidForm.name && <p className="message-err">
                                            Chưa nhập tên chi nhánh
                                        </p>}
                                    </>
                                    :
                                    <p className="info">{branchDetail.name}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="address" className="label asterisk">Địa chỉ</Label>
                            {
                                isEdit ?
                                    <>
                                        <Input id="address" name="address" className="input-edit-info input-textarea" type="textarea" onChange={(e) => setBranchDetail({ ...branchDetail, address: e.target.value })} value={branchDetail.address} required />
                                        {invalidForm.address && <p className="message-err">
                                            Chưa nhập địa chỉ chi nhánh
                                        </p>}
                                    </>
                                    :
                                    <p className="info">{branchDetail.address}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="username" className="label">Tài khoản quản lý</Label>
                            {
                                isEdit ? (
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <Input id="username" className="input-edit-info" name="username" type="text" onChange={(e) => setBranchDetail({ ...branchDetail, userManager: e.target.value })} value={branchDetail.userManager} />
                                        <Button
                                            className="btn-test"
                                            color="info"
                                            outline
                                        >
                                            <span>
                                                Kiểm tra
                                            </span>
                                        </Button>
                                    </div>
                                ) : <p className="info">{branchDetail.userManager}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="manager" className="label">Họ tên quản lý</Label>
                            {
                                isEdit ? <>
                                    <Input invalid={invalidForm.fullNameManager} id="manager" style={invalidForm.fullNameManager ? {} : { border: "none !important" }} className="input-edit-info disabled" aria-errormessage="err" name="manager" type="text" placeholder="Tự động nhập từ tên đăng nhập" disabled />
                                    {invalidForm.fullNameManager && <p className="message-err">
                                        Chưa kiểm tra tên đăng nhập
                                    </p>}
                                </> : <p className="info">{managerDetail.fullNameManager}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="phone" className="label">Số điện thoại quản lý</Label>
                            {
                                isEdit ? <>
                                    <Input invalid={invalidForm.phoneManager} id="phone" style={invalidForm.phoneManager ? {} : { border: "none !important" }} className="input-edit-info disabled" name="phone" aria-errormessage="err" type="text" placeholder="Tự động nhập từ tên đăng nhập" disabled />
                                    {invalidForm.phoneManager && <p className="message-err">
                                        Chưa kiểm tra tên đăng nhập
                                    </p>}
                                </> : <p className="info">{managerDetail.phoneManager}</p>
                            }
                        </div>
                        <div>
                            <Label htmlFor="status" className="label asterisk">Trạng thái</Label>
                            <div className="d-flex flex-row align-items-center">
                                {
                                    isEdit ? <div className="form-switch me-1">
                                        <input className="form-check-input" name="status" type="checkbox" role="switch" id="switchCheckChecked" checked={branchDetail.status === "ACTIVE"} onChange={() => setBranchDetail({ ...branchDetail, status: branchDetail.status === "ACTIVE" ? "INACTIVE" : "ACTIVE" })} required />
                                    </div> : <></>
                                }
                                {
                                    branchDetail.status === "ACTIVE" ?
                                        (
                                            <span>
                                                Hoạt động
                                            </span>
                                        ) : (
                                            <span>
                                                Không hoạt động
                                            </span>
                                        )
                                }

                            </div>
                        </div>
                    </div>
                    <div className="mt-3 d-flex flex-row justify-content-end">
                        {
                            isEdit ? (
                                <>
                                    <Button
                                        color="success"
                                        outline
                                        className="me-2"
                                        onClick={handleSubmit}
                                    >
                                        Lưu lại
                                    </Button>
                                    <Button
                                        color="danger"
                                        outline
                                        onClick={() => history.push(`/branch/details/${code}`)}
                                    >
                                        Huỷ bỏ
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        color="warning"
                                        outline
                                        className="me-2"
                                        onClick={() => history.push(`/branch/edit/${code}`)}
                                    >
                                        Chỉnh sửa
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                    >
                                        <Link to="/branch">
                                            Trở về
                                        </Link>
                                    </Button>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}