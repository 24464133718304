import React from 'react'
import { SHOW_TOAST_ERROR_DEFAULT, SHOW_TOAST_SUCCESS_DEFAULT, SHOW_TOAST_WARNING_DEFAULT } from '../../store/toast/actionTypes';
import { Alert } from 'reactstrap';

const ToastItem = ({ type, message }) => {
	
	const colorCode = {
		[SHOW_TOAST_SUCCESS_DEFAULT]: "success",
		[SHOW_TOAST_WARNING_DEFAULT]: "warning",
		[SHOW_TOAST_ERROR_DEFAULT]: "danger"
	}

	return (
		<Alert color={colorCode[type]}>
			{message}
		</Alert>
	)
}

export default ToastItem