import "./index.css";
import {Col, Row} from "reactstrap";
import React, { useState } from "react";
import TableInfo from "./components/TableInfo";
export default function Branch(){
    const [perPage, setPerPage] = useState("10");
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CHI NHÁNH</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Chi nhánh </b>
                        <p className="mb-0"> / Danh sách</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h5 className="heading">Danh sách</h5>
                    <div className="filter d-flex flex-row justify-content-between align-items-center mb-4">
                        <div className="">
                            <span>Hiện </span>
                            <select className="select-wrapper" value={perPage} onChange={(e) => setPerPage(e.target.value)}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span> hàng</span>
                        </div>
                        <div>
                            <input type="text" className="input" onChange={(e) => setSearchInput(e.target.value)} />
                            <button
                                className="ms-1 py-0 px-1 border border-info bg-transparent rounded"
                                onClick={() => setSearch(searchInput)}
                            >
                                <span className="text-info">Tìm</span>
                            </button>
                        </div>
                    </div>
                    <TableInfo perPage={perPage} search={search} />
                </div>
            </div>
        </React.Fragment>
    )
}