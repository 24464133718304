import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toastIdSelector, toastMessageSelector, toastTypeSelector } from '../../store/toast/selectors';
import ToastItem from './ToastItem';

const Toasts = () => {
	const toastId = useSelector(toastIdSelector);
	const toastType = useSelector(toastTypeSelector);
	const toastMessage = useSelector(toastMessageSelector);

	const [toasts, setToasts] = useState([]);

	useEffect(() => {
		if (toastId) {
			setToasts((prevToasts) => [...prevToasts, {
				id: toastId,
				type: toastType,
				message: toastMessage
			}]);

			setTimeout(() => {
				setToasts((prevToasts) => [...prevToasts.filter(toast => toast.id !== toastId)]);
			}, 5000);
		}
	}, [toastId]);

	if (toasts.length > 0) {
		return <div className='position-absolute top-0 end-0 w-auto h-auto mt-3 me-3' style={{ zIndex: 9999 }}>
			{toasts.map(toast => (
				<ToastItem key={toast.id} type={toast.type} message={toast.message} />
			))}
		</div>
	} else {
		return <></>
	}
}

export default Toasts