import "./index.css";
import {Col, Row} from "reactstrap";
import React, { useState } from "react";
import ListSchedule from "./components/list-schedule";

export default function ScheduleList(){
    const [perPage, setPerPage] = useState("10");
    const [searchStatus, setSearchStatus] = useState("ACTIVE");

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CA LÀM VIỆC</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Ca làm việc </b>
                        <p className="mb-0"> / Danh sách</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h5 className="heading">Danh sách</h5>
                    <div className="filter d-flex flex-row justify-content-between align-items-center mb-4">
                        <div className="">
                            <span>Hiện </span>
                            <select className="select-wrapper" value={perPage} onChange={(e) => setPerPage(e.target.value)}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span> hàng</span>
                        </div>
                        <div>
                            <select className="select-wrapper" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                <option value="ACTIVE">Hoạt động</option>
                                <option value="INACTIVE">Không hoạt động</option>
                            </select>
                        </div>
                    </div>
                    <ListSchedule perPage={perPage} searchStatus={searchStatus} />
                </div>
            </div>
        </React.Fragment>
    )
}