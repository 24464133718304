import { SHOW_TOAST_ERROR_DEFAULT, SHOW_TOAST_SUCCESS_DEFAULT, SHOW_TOAST_WARNING_DEFAULT } from "./actionTypes"
import { v4 } from 'uuid'

export const showToastSuccessDefault = (message) => {
	return {
		type: SHOW_TOAST_SUCCESS_DEFAULT,
		payload: {
			id: v4(),
			message: message
		}
	}
}

export const showToastWarningDefault = (message) => {
	return {
		type: SHOW_TOAST_WARNING_DEFAULT,
		payload: {
			id: v4(),
			message: message
		}
	}
}

export const showToastErrorDefault = (message) => {
	return {
		type: SHOW_TOAST_ERROR_DEFAULT,
		payload: {
			id: v4(),
			message: message
		}
	}
}