import { SHOW_TOAST_ERROR_DEFAULT, SHOW_TOAST_SUCCESS_DEFAULT, SHOW_TOAST_WARNING_DEFAULT } from "./actionTypes"

const initialState = {
	id: null,
	type: null,
	message: ""
}

const toast = (state = initialState, action) => {
	switch (action.type) {
		default:
			state = {
				type: action.type,
				...action.payload
			}
	}
	return state
}

export default toast