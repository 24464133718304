import "./index.css";
import BranchDetails from "./BranchDetails";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useEffect, useState } from "react";
import { post } from "../../../../../helpers/api_helper";
import { POST_BRANCH_SEARCH } from "../../../../../helpers/url_helper";
import ReactPaginate from "react-paginate";
export default function TableInfo({ perPage, search }) {
    const [branches, setBranches] = useState([]);
    const [currentPerPage, setCurrentPerPage] = useState(perPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        post(POST_BRANCH_SEARCH, {
            "keyword": search,
            "page": currentPage,
            "limit": currentPerPage,
            "orderBy": ""
        }).then(response => {
            if (response.status === 200) {
                setBranches(response.body.content);
                setTotalPages(response.body.totalPages);
            }
        })
    }, [currentPage, currentPerPage, search]);

    useEffect(() => {
        setCurrentPerPage(perPage);
        setCurrentPage(1);
        setTotalPages(1);
    }, [perPage]);

    const handlePageClick = (page) => {
        setCurrentPage(page + 1);
    }

    return (
        <div className="table-info panel panel-default">
            <table className="table table-striped table-bordered table-responsive mb-3">
                <thead className="border">
                    <tr>
                        <th style={{width: "40%"}}>Mã chi nhánh</th>
                        <th>Tên chi nhánh</th>
                    </tr>
                </thead>
                <tbody>
                    {branches && branches.map(branch =>
                        <BranchDetails key={branch.code} id={branch.code} name={branch.name} manager={branch.fullNameManager} status={branch.status === "ACTIVE" ?? false} />
                    )}
                </tbody>
            </table>
            <ReactPaginate
                breakLabel="..."
                nextLabel="Sau"
                onPageChange={({ selected }) => handlePageClick(selected)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={totalPages}
                forcePage={currentPage - 1}
                previousLabel="Trước"
                renderOnZeroPageCount={null}
                className="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
            />
        </div>
    )
}