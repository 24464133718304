import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import "./index.css";
import { useState } from "react";
export default function BranchDetails(props) {
    const {id, name, manager, status} = props;

    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleDetails = () => {
        setIsCollapsed(!isCollapsed);
    }
    return (
        <>
            <tr className="clickable" onClick={() => {
                toggleDetails();
            }}>
                <td className="d-flex flex-row">
                    <button className="btn btn-default btn-sm pt-0 pb-0">
                        <i className="bx bxs-plus-circle h5 mb-0 text-primary"></i>
                    </button>
                    <span className="pb-1">
                        {id}
                    </span>
                </td>
                <td>{name}</td>
            </tr>
            {!isCollapsed && <>
            <tr className="extended-row-item">
                <td>Họ tên quản lý</td>
                <td>
                    <i>{manager}</i>
                </td>
            </tr>
            <tr className="extended-row-item">
                <td>Trạng thái</td>
                <td>
                    <div className="form-switch ps-0">
                        { status ? <i className="text-success">Hoạt động</i> : <i className="text-danger">Không hoạt động</i> }
                    </div>
                </td>
            </tr>
            <tr className="extended-row-item">
                <td>Tác vụ</td>
                <td>
                    <UncontrolledDropdown
                        className="me-2"
                        direction="down"
                    >
                        <DropdownToggle
                            caret
                            className="dropdown-part"
                        >
                            <i className="bx bx-dots-horizontal-rounded option-icon"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <Link to={`/branch/details/${id}`}>
                                    Chi tiết
                                </Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Link to={`/branch/edit/${id}`}>
                                    Chỉnh sửa
                                </Link>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </td>
            </tr>
            </>}
        </>
    )
}